import axios from "axios";

const instance = axios.create({
  //baseURL: "http://localhost:5000", // 后端 API 地址
  timeout: 50000, // 请求超时时间
});

// 请求拦截器，将 Token 添加到请求头
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
