<template>
  <div class="pagination">
    <el-button
      @click="prevPage"
      :disabled="currentPage === 1"
      circle
      class="el-icon-caret-left"
      type="primary"
    ></el-button>
    <span>第 {{ currentPage }} 页</span>
    <el-button
      @click="nextPage"
      :disabled="currentPage === totalPages"
      circle
      class="el-icon-caret-right"
      type="primary"
    ></el-button>
    <span>共 {{ totalItems }} 条</span>
  </div>
</template>
<script>
export default {
  props: {
    totalItems: {
      type: Number,
      required: true,
    },
    initialPageSize: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      pageSize: this.initialPageSize,
      currentPage: 1,
    };
  },
  mounted() {
    window.addEventListener("keydown", this.handleKeyDown);
  },
  destroyed() {
    window.removeEventListener("keydown", this.handleKeyDown);
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalItems / this.pageSize);
    },
  },

  methods: {
    handleKeyDown(event) {
      if (event.key === "ArrowLeft") {
        event.preventDefault();
        event.stopPropagation();
        this.prevPage();
      } else if (event.key === "ArrowRight") {
        event.preventDefault();
        event.stopPropagation();
        this.nextPage();
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.emitPagechange();
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.emitPagechange();
      }
    },

    emitPagechange() {
      this.$emit("page-change", {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
      });
    },
    updateTotalPages() {
      if (this.currentPage > this.totalPages) {
        this.currentPage = this.totalPages;
      }
      this.emitPagechange();
    },
  },
};
</script>
<style scoped>
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination button {
  margin: 0 5px;
}
.pagination select {
  margin-left: 10px;
}
.el-button.is-circle {
  padding: 5px;
}
.el-input__inner {
  height: 30px;
}
.el-input__icon,
.el-input__inner {
  line-height: 30px;
}
</style>
