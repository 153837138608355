<template>
  <div>
    <div class="login">
      <h2 class="title">{{ showRegisterForm ? "REGISTER" : "LOGIN" }}</h2>
      <form v-if="!showRegisterForm" @submit.prevent="login">
        <div>
          <label for="username">Username:</label>
          <input type="text" v-model="username" required />
        </div>
        <div>
          <label for="password">Password:</label>
          <input type="password" v-model="password" required />
        </div>

        <button type="submit">Login</button>
        <span @click="toggleForm('register')" class="tip_text">注册</span>
      </form>
      <form v-if="showRegisterForm" @submit.prevent="register">
        <div>
          <label for="newUsername">New Username:</label>
          <input type="text" v-model="newUsername" required />
        </div>
        <div>
          <label for="newPassword">New Password:</label>
          <input type="password" v-model="newPassword" required />
        </div>
        <button type="submit">Register</button>
        <span class="tip_text" @click="toggleForm('login')">返回</span>
      </form>
    </div>
  </div>
</template>

<script>
import { createUser, login } from "@/api/user";

export default {
  name: "LoginForm",
  data() {
    return {
      username: "",
      password: "",
      showRegisterForm: false,
      newUsername: "",
      newPassword: "",
    };
  },
  methods: {
    toggleForm(formType) {
      this.showRegisterForm = formType === "register";

      this.username = "";
      this.password = "";
      this.newUsername = "";
      this.newPassword = "";
    },
    async register() {
      try {
        const result = await createUser(this.newUsername, this.newPassword);

        if (result.message === "User created successfully") {
          this.$message({
            message: "注册成功！",
            type: "success",
          });
          this.$router.push("/home");
        }
      } catch (error) {
        console.error("Error creating user:", error.message);
      }
    },
    async login() {
      try {
        const result = await login(this.username, this.password);
        console.log("User created:", result);
        if (result.success == true) {
          this.$router.push("/home");
        }
      } catch (error) {
        if (error.message === "Invalid credentials") {
          this.$message.error("密码不正确！");
        } else if (error.message === "User not found") {
          this.$message.error("用户名不存在，请先注册！");
        } else {
          console.error("Error login user:", error.message);
          this.$message.error("登录失败，请重试！");
        }
      }
    },
    // login() {
    //   this.$http
    //     .post("/api/login", {
    //       username: this.username,
    //       password: this.password,
    //     })
    //     .then((response) => {
    //       if (response.data.message === "Login successful") {
    //         const token = "dfzsdgvzgbvfdbfbdxzbfb";
    //         localStorage.setItem("token", token);
    //         this.$router.push("/home");
    //       } else {
    //         alert("Login failed");
    //       }
    //     })
    //     .catch((error) => {
    //       console.error("Login error:", error);
    //       alert("Login failed");
    //     });
    // },
  },
};
</script>
<style scoped>
.tip_text {
  text-decoration: underline;
  margin-left: 10px;
  cursor: pointer;
}
.title {
  text-align: center;
  font-weight: bold;
  color: #333333;
  font-size: 20px;
  margin-bottom: 10px;
}
.login {
  max-width: 300px;
  margin: 0 auto;
  padding: 1em;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 100px;
}
.login div {
  margin-bottom: 1em;
}
.login label {
  margin-bottom: 0.5em;
  color: #333333;
  display: block;
}
.login input {
  border: 1px solid #cccccc;
  padding: 0.5em;
  font-size: 1em;
  width: 100%;
  box-sizing: border-box;
}
.login button {
  padding: 0.7em;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.login button:hover {
  background-color: #0056b3;
}
</style>
