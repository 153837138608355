<template>
  <div>
    <div style="margin: 60px">
      <el-row style="min-width: 860px">
        <el-col :span="12">
          <el-input
            ref="verFocus"
            v-model="input"
            placeholder="请输入内容"
            style="max-width: 600px"
            @keyup.enter.native="handleChange(1, input)"
          >
            <el-button
              type="primary"
              slot="append"
              icon="el-icon-search"
              :disabled="disabledInput"
              @click="handleChange(1, input)"
            ></el-button
          ></el-input>
          <br />
          <el-select
            v-model="mainTable"
            placeholder="请选择表"
            style="width: 100px; margin-top: 10px"
            @change="() => handleChangeFilter(7, mainTable)"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-col>
        <el-col :span="12">
          <el-descriptions
            class="margin-top"
            :column="2"
            border
            v-if="this.dataDetails.length != 0"
          >
            <el-descriptions-item>
              <template slot="label"> 总条数 </template>
              {{ dataDetails.total }}
            </el-descriptions-item>

            <el-descriptions-item>
              <template slot="label"> 手工判断数 </template>
              {{ dataDetails.totalVer }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 关键词匹配率 </template>
              {{
                ((dataDetails.totalKeyword / dataDetails.total) * 100).toFixed(
                  3
                )
              }}%
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 反向率 </template>
              {{
                ((dataDetails.totalTitle / dataDetails.total) * 100).toFixed(3)
              }}%
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 正向率 </template>
              {{
                ((dataDetails.totalCont / dataDetails.total) * 100).toFixed(3)
              }}%
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 通过率 </template>
              {{
                ((dataDetails.throughCount / dataDetails.total) * 100).toFixed(
                  3
                )
              }}%
            </el-descriptions-item>
          </el-descriptions>
        </el-col>
      </el-row>

      <div class="pagination-container">
        <PaginationComp
          :total-items="totalItems"
          :initial-page-size="pageSize"
          :current-page.sync="currentPage"
          :disabled="isLoading"
          @page-change="handlePageChange"
        />
      </div>

      <div class="table-responsive" v-loading="isLoading">
        <table class="table">
          <colgroup>
            <col width="80" />
            <col width="400" />
            <col width="160" />
            <col width="200" />
            <col width="180" />
            <col width="180" />
            <col width="180" />
            <col width="180" />
            <col width="180" />
          </colgroup>
          <thead>
            <tr>
              <th>编号</th>
              <th>标题</th>
              <th>
                <div class="header-container">
                  <div class="header-label">判断</div>
                  <div class="header-controls">
                    <el-select
                      v-model="filterVer"
                      placeholder="选择"
                      @change="getFilter(1, filterVer)"
                    >
                      <el-option label="全部" value="all"></el-option>
                      <el-option label="是政策" value="1"></el-option>
                      <el-option label="非政策" value="0"></el-option>
                      <el-option label="已判断" value="NOTNULL"></el-option>
                      <el-option label="未判断" value="null"></el-option>
                    </el-select>
                  </div>
                </div>
              </th>
              <th>
                <div class="header-container">
                  <div class="header-label">发布时间</div>

                  <div class="header-controls">
                    <el-select
                      v-model="filterDate"
                      placeholder="排序类型"
                      @change="handleChange(2, filterDate)"
                    >
                      <el-option label="全部" value="all"></el-option>
                      <el-option label="日期" value="date"></el-option>
                    </el-select>

                    <el-popover
                      v-show="filterDate == 'date'"
                      placement="top-start"
                      title="选择日期"
                      width="260"
                      trigger="hover"
                    >
                      <el-date-picker
                        v-model="selectedDate"
                        type="date"
                        placeholder="选择日期"
                        size="small"
                        @change="handleChange(2, selectedDate)"
                      />
                      <template #reference>
                        <el-button type="text">选择日期</el-button>
                      </template>
                    </el-popover>
                  </div>
                </div>
              </th>
              <th v-if="mainTable == 'gov_gz_thousand'">
                <div class="header-container">
                  <div class="header-label">市区筛选</div>
                  <div class="header-controls">
                    <el-select
                      v-model="filterArea"
                      placeholder="选择"
                      @change="getFilter(8, filterArea)"
                    >
                      <el-option
                        v-for="(item, index) in filteredDistricts"
                        :key="index"
                        :label="item.name"
                        :value="item.name"
                      ></el-option>
                    </el-select>
                  </div>
                </div>
              </th>
              <th v-if="mainTable == 'gov_gz_thousand'">
                <div class="header-container">
                  <div class="header-label">市区匹配</div>
                  <div class="header-controls">
                    <el-select
                      v-model="filterMate"
                      placeholder="选择"
                      @change="getFilter(9, filterMate)"
                    >
                      <el-option label="全部" value="all"></el-option>
                      <el-option label="通过" value="1"></el-option>
                      <el-option label="排除" value="0"></el-option>
                    </el-select>
                  </div>
                </div>
              </th>
              <th>
                <div class="header-container">
                  <div class="header-label">补助通知</div>
                  <div class="header-controls">
                    <el-select
                      v-model="filterAssit"
                      placeholder="选择"
                      @change="getFilter(2, filterAssit)"
                    >
                      <el-option label="全部" value="all"></el-option>
                      <el-option label="通过" value="oneUnknown"></el-option>
                      <el-option label="一定是" value="1"></el-option>
                      <el-option label="不确定" value="unknown"></el-option>
                      <el-option label="一定不是" value="0"></el-option>
                    </el-select>
                  </div>
                </div>
              </th>
              <th>
                <div class="header-container">
                  <div class="header-label">标题反向</div>

                  <div class="header-controls">
                    <el-select
                      v-model="selectedFTitle"
                      placeholder="选择"
                      @change="() => handleChangeFilter(4, selectedFTitle)"
                    >
                      <el-option
                        v-for="item in titleKeywords"
                        :key="item.id"
                        :label="item.keyword"
                        :value="item"
                      ></el-option>
                    </el-select>
                  </div>
                </div>
              </th>
              <th>
                <div class="header-container">
                  <div class="header-label">正文正向</div>

                  <div class="header-controls">
                    <el-select
                      v-model="selectedFContent"
                      placeholder="选择"
                      @change="() => handleChangeFilter(5, selectedFContent)"
                    >
                      <el-option
                        v-for="item in contentKeywords"
                        :key="item.id"
                        :label="item.keyword"
                        :value="item"
                      ></el-option>
                    </el-select>
                  </div>
                </div>
              </th>
              <th v-if="mainTable == 'gov_gz_infos_sign'">
                <div class="header-container">
                  <div class="header-label">新反向</div>
                  <div class="header-controls">
                    <el-select
                      v-model="newFilterTitle"
                      placeholder="选择"
                      @change="getFilter(3, newFilterTitle)"
                    >
                      <el-option label="全部" value="all"></el-option>
                      <el-option label="通过" value="1"></el-option>
                      <el-option label="排除" value="0"></el-option>
                    </el-select>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <template v-if="this.tableData.length > 0">
            <tbody v-for="(item, index) in this.tableData" :key="index">
              <tr>
                <th>
                  {{ (currentPage - 1) * pageSize + index + 1 }}
                </th>
                <th style="line-height: 20px">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="点击跳转详情"
                    placement="top"
                  >
                    <a
                      target="_blank"
                      :href="item.post_url"
                      style="color: #606266; text-decoration: none"
                      >{{ item.title }}</a
                    >
                  </el-tooltip>
                </th>
                <th>
                  <el-radio-group
                    v-model="item.ver"
                    @change="verificatChange(item.pid, item.ver)"
                    ><el-radio :label="1" style="margin-bottom: 10px"
                      >是政策</el-radio
                    >

                    <el-radio :label="0">非政策</el-radio>
                  </el-radio-group>
                </th>
                <th>{{ convertUnixTimestampToDate(item.ptimeint) }}</th>
                <th v-if="mainTable == 'gov_gz_thousand'">{{ item.type_3 }}</th>
                <th v-if="mainTable == 'gov_gz_thousand'">{{ item.type_1 }}</th>
                <th>{{ item.type_0 }}</th>
                <th>{{ item.keyword_0 }}</th>
                <th>{{ item.keyword_1 }}</th>
                <th v-if="mainTable == 'gov_gz_infos_sign'">
                  {{ item.keyword_2 }}
                </th>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th colspan="2">
                  <el-button type="primary" round @click="handleSubmit(1)"
                    >本页全部是政策</el-button
                  >
                  <el-button type="primary" round @click="handleSubmit(0)"
                    >本页全部非政策</el-button
                  >
                  <el-button
                    type="primary"
                    round
                    @click="handleChangeFilter(6, true)"
                    >随机获取10条</el-button
                  >
                </th>
                <th colspan="6"></th>
              </tr>
            </tfoot>
          </template>
          <template v-else
            ><tbody>
              <th colspan="8">无数据...</th>
            </tbody></template
          >
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import PaginationComp from "@/components/PaginationComp.vue";

import {
  getData,
  getKeywords,
  updateVer,
  updateVers,
  getDataDetail,
  getDistrict,
} from "@/api/data";
export default {
  name: "HomePage",
  components: {
    PaginationComp,
  },
  data() {
    return {
      filterDate: "all",
      selectedDate: "",
      currentPage: 1,
      pageSize: 10,
      tableData: [],
      totalItems: 0,
      input: "",
      disabledInput: false,
      filterVer: "",
      filterAssit: "",
      isLoading: false,
      dataDetails: [],
      selectedFTitle: null,
      selectedFContent: null,
      filterTitle: [],
      filterContent: [],
      titleKeywords: [],
      contentKeywords: [],
      random: false,
      newFilterTitle: "",
      mainTable: "gov_gz_thousand",
      options: [
        {
          value: "gov_gz_infos_sign",
          label: "主表",
        },
        {
          value: "gov_gz_thousand",
          label: "附表",
        },
      ],
      filterArea: "",
      district: [],
      filterMate: "",
    };
  },
  computed: {
    fetchDataParams() {
      return {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        selectedDate: this.selectedDate,
        input: this.input,
        filterVer: this.filterVer,
        filterTitle: this.filterTitle,
        filterContent: this.filterContent,
        random: this.random,
        newFilterTitle: this.newFilterTitle,
        filterAssit: this.filterAssit,
        mainTable: this.mainTable,
        filterArea: this.filterArea,
        filterMate: this.filterMate,
      };
    },
    filteredDistricts() {
      // 过滤掉非对象类型的属性
      return this.district.filter(
        (item) => typeof item === "object" && item.id && item.name
      );
    },
  },
  created() {},
  async mounted() {
    this.getKeywords();
    this.getDataDetail();
    this.fetchTableData();
    await this.getDistrict();
  },
  beforeDestroy() {},
  methods: {
    //获取区域
    async getDistrict() {
      try {
        const response = await getDistrict();
        const rawData = response.data;
        this.district = rawData.flat();

        this.district.unshift(
          { id: "all", name: "全部" },
          { id: "1", name: "通过" },
          { id: "0", name: "排除" },
          { id: "level", name: "广州" }
        );
      } catch (error) {
        console.error("Error:", error);
      }
    },
    //获取数据详情
    async getDataDetail() {
      try {
        const response = await getDataDetail();
        this.dataDetails = response.data;
      } catch (error) {
        console.error("Error:", error);
      }
    },
    //获取关键词数组和处理
    handleChangeFilter(type, selected) {
      const filterContent = [
        { keyword: selected.keyword, field: selected.field },
      ];
      if (type === 4) this.selectedFTitle = selected.keyword;
      if (type === 5) this.selectedFContent = selected.keyword;
      if (type === 6) this.random = selected;
      if (type === 7) this.mainTable = selected;

      this.getFilter(type, filterContent);
    },

    addObjectsToArray(array) {
      if (array.length === 0) return array;
      const field = array[0].field;
      const maxId = array.reduce(
        (max, item) => (item.id ? Math.max(max, item.id) : max),
        0
      );
      const newObjects = [
        { id: maxId + 1, keyword: "全部", field: field },
        { id: maxId + 2, keyword: "通过", field: field },
        { id: maxId + 3, keyword: "排除", field: field },
      ];

      if (
        !array.some((item) => Object.prototype.hasOwnProperty.call(item, "id"))
      ) {
        newObjects.forEach((obj) => delete obj.id);
      }

      return [...newObjects, ...array];
    },

    async getKeywords() {
      try {
        const response = await getKeywords();
        this.titleKeywords = this.addObjectsToArray(response.data.data.title);
        this.contentKeywords = this.addObjectsToArray(
          response.data.data.content
        );
      } catch (error) {
        console.error("Error:", error);
      }
    },

    //政策判断、标题、正文
    getFilter(type, val) {
      this.currentPage = 1;
      if (type === 1) {
        this.filterVer = val;
        this.random = false;
      }
      if (type === 2) this.filterAssit = val;
      if (type === 3) this.newFilterTitle = val;
      if (type === 4) this.filterTitle = val;
      if (type === 5) this.filterContent = val;
      if (type === 8) this.filterArea = val;
      if (type === 9) this.filterMate = val;

      this.fetchTableData();
    },

    //整页提交
    async handleSubmit(val) {
      const pids = this.tableData.map((item) => item.pid);

      const tip = val === 1 ? "政策" : "非政策";
      try {
        const response = await updateVers(pids, val);

        this.$message({
          message: `提交${tip}成功，${response.affectedRows}条`,
          type: "success",
        });

        this.fetchTableData();
      } catch (error) {
        console.error("Error:", error);
        this.$message.error("提交失败!");
      }
    },

    //日期、范围、补助、排序、搜索
    handleChange(type, val) {
      if (type == 1) {
        this.input = val;
        this.disabledInput = true;
        if (this.filterAssit == "1") {
          this.filterTitle = "";
          this.filterContent = "";
          this.newFilterTitle = "";
        }
      }

      if (type == 2) {
        if (this.filterDate == "all") this.selectedDate = "";
        if (val instanceof Date) this.selectedDate = val;
      }

      this.fetchTableData();
    },

    //请求数据
    async fetchTableData() {
      this.isLoading = true;
      try {
        const data = await getData(this.fetchDataParams);

        this.tableData = data.data.results;
        this.totalItems = data.data.total;

        this.disabledInput = false;
      } catch (error) {
        console.error("Error loading data:", error);
      } finally {
        this.isLoading = false;
      }
    },

    //切换页面
    handlePageChange({ currentPage, pageSize }) {
      this.paginationActive = true;
      this.currentPage = currentPage;
      this.pageSize = pageSize;
      this.random = false;

      this.fetchTableData();
    },

    //单个判断
    verificatChange(pid, val) {
      this.$refs.verFocus.focus();
      updateVer(pid, val)
        .then(() => {
          this.$message({
            message: "核实成功",
            type: "success",
          });
          this.fetchTableData();
        })
        .catch((error) => {
          console.log("Update failed:", error);
          this.$message.error("核实失败");
        });
    },

    //处理ptimeint
    convertUnixTimestampToDate(unixTimestamp) {
      const date = new Date(unixTimestamp * 1000);
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, "0");
      const day = String(date.getUTCDate()).padStart(2, "0");

      return `${year}-${month}-${day}`;
    },
  },
};
</script>
<style scoped>
progress {
  width: 100%;
  height: 20px;
}
.table-responsive {
  min-width: 1200px;
  overflow: auto;
}
table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}
td,
th {
  border: 1px solid #ebeef5;
  text-align: left;
  padding: 10px;
  vertical-align: middle;
  color: #909399;
}
.el-pagination .el-select .el-input {
  margin: none;
}
.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-label {
  flex: 1;
}

.header-controls {
  width: 90px;
}
.arrow-up,
.arrow-down {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 5px;
  vertical-align: middle;
  content: "";
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}
.arrow-up {
  border-bottom: 5px solid currentColor;
}
.arrow-down {
  border-top: 5px solid currentColor;
}
.el-button.is-circle {
  padding: 2px;
}
.pagination-container {
  display: flex;
  justify-content: left;
  margin: 20px 0;
}
</style>
