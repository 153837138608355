import axios from "../utils/axiosInstance";

export const getData = async (params) => {
  try {
    const response = await axios.get("/api/gov_infos_page", { params });
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const getKeywords = async () => {
  try {
    const response = await axios.get("/api/get_keywords");
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const updateVer = async (pid, ver) => {
  try {
    const response = await axios.post("/api/update_ver", { pid, ver });
    return response;
  } catch (error) {
    console.error("Error updating ver:", error);
    throw error;
  }
};

export const updateVers = async (pids, ver) => {
  try {
    const response = await axios.post("/api/update_vers", {
      pids,
      ver,
    });
    return response.data;
  } catch (error) {
    console.error("Error updating ver:", error);
    throw error;
  }
};

export const getDataDetail = async () => {
  try {
    const response = await axios.get("/api/get_data_detail");
    return response.data;
  } catch (error) {
    console.error("Error updating ver:", error);
    throw error;
  }
};

export const getDistrict = async () => {
  try {
    const response = await axios.get("/api/getDistrict");
    return response.data;
  } catch (error) {
    console.error("Error getDistrict:", error);
    throw error;
  }
};
