import Vue from "vue";

import Router from "vue-router";
import Home from "../views/home";
import Login from "../views/login";

Vue.use(Router);
//Vue.use(VueRouter);

const router = new Router({
  routes: [
    {
      path: "/home",
      name: "Home",
      component: Home,
      meta: { requiresAuth: true }, // 需要登录后才能访问的页面
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
    {
      path: "*",
      name: "login",
      redirect: "/login",
    },
  ],
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem("token"); // 检查 localStorage 中是否有 token
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      next({
        path: "/login",
        query: { redirect: to.fullPath }, // 将要访问的路径保存在 query 中，登录成功后可重定向回来
      });
    } else {
      next(); // 已登录，允许访问
    }
  } else {
    next(); // 不需要登录的页面直接放行
  }
});

export default router;
